@import '/src/colors.less';

.container {
  height: 100%;
  overflow: hidden;
}

.header {
  position: sticky;
  display: flex;
  gap: 6px;
  margin-bottom: 6px;

  .logo {
    background-color: @neutral-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    height: 100%;

    img {
      width: 24px;
    }
  }

  .menu,
  .user,
  .actions {
    background-color: @header-color;
  }

  .menu {
    flex: 1;
  }

  .user {
    padding: 0 8px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .action {
    background-color: @header-color;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  @media (max-width: 575px) {
    .user {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@primary-color: #393536;@success-color: #1de9b6;@layout-header-background: #f2f3f4;@layout-header-height: 50px;@layout-header-padding: 0;@layout-header-color: #000000;@border-radius-base: 6px;@height-lg: 56px;@select-single-item-height-lg: 56px;