@import '/src/colors.less';

.container {
  background-color: @body-color;
  margin-top: 20vh;
  display: flex;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 520px;
  max-width: 100%;
  overflow-x: auto;

  @media (max-height: 500px) {
    margin-top: 0;
    height: 100%;
  }
}

.header {
  gap: 8px;

  h1 {
    font-size: 2.222em;
    font-weight: 500;
    margin: 0;
  }

  span {
    font-weight: 400;
    margin: 0;
  }
}

.button {
  font-size: 0.778em;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

@primary-color: #393536;@success-color: #1de9b6;@layout-header-background: #f2f3f4;@layout-header-height: 50px;@layout-header-padding: 0;@layout-header-color: #000000;@border-radius-base: 6px;@height-lg: 56px;@select-single-item-height-lg: 56px;