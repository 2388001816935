.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 32px;
  margin-bottom: 32px;
}

.header {
  max-width: 500px;

  .title {
    font-size: 2.222em;
    font-weight: 500;
    margin-bottom: 8px !important;
  }

  .subtitle {
    font-weight: 400;
    margin: 0;
  }
}

@primary-color: #393536;@success-color: #1de9b6;@layout-header-background: #f2f3f4;@layout-header-height: 50px;@layout-header-padding: 0;@layout-header-color: #000000;@border-radius-base: 6px;@height-lg: 56px;@select-single-item-height-lg: 56px;