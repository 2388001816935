@import '/src/colors.less';

.container {
  background-color: @body-color;
  width: 800px;
  max-width: 100%;
  height: 100%;
  overflow-x: auto;
}

.tabs {
  :global {
    .ant-tabs-nav {
      width: 100% !important;
      margin-bottom: 32px;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
      font-weight: 700;
      color: @neutral-grey;
      text-transform: uppercase;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @primary-green !important;
      }
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-ink-bar {
      background: @primary-green;
    }
  }
}

@primary-color: #393536;@success-color: #1de9b6;@layout-header-background: #f2f3f4;@layout-header-height: 50px;@layout-header-padding: 0;@layout-header-color: #000000;@border-radius-base: 6px;@height-lg: 56px;@select-single-item-height-lg: 56px;